import { useCallback, useMemo, useRef, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import type {
  ApiListingRequest,
  TableToolbarFilterConfig,
  TableWrapperRef,
} from '@bitstopco/bitstop-theme';
import { TableWrapper, useModal } from '@bitstopco/bitstop-theme';

import cloneDeep from 'lodash/cloneDeep';
import { useNavigate } from 'react-router';

import CardTable from '@/components/ui/card/CardTable';
import { useGetAllTagsByTermQuery } from '@/store/api/services/common/tags';
import {
  useGetMachinesExportMutation,
  useGetMachinesQuery,
  useGetMachinesSeveritiesQuery,
} from '@/store/api/services/machines/machines';

import { DEFAULT_PAGINATION } from '@/constants/api';
import {
  MACHINES_COLUMNS,
  MACHINES_FILTERS_CONFIG,
  MACHINES_MULTI_SELECT_CONFIG,
} from '@/constants/app/machines';

import type { Machine, Tag, WithTags } from '@/types';

type MultiSelectAction = {
  id: string;
  getProps: (atms: Machine[], multiSelectValues: boolean[]) => object;
};

const getSelectedAtmGlobalIds = (atms: Machine[], multiSelectValues: boolean[]) =>
  multiSelectValues
    .map((isSelected, index) => isSelected && atms[index]?.global_id)
    .filter(Boolean) as string[];

const multiSelectActions: Record<number, MultiSelectAction> = {
  0: {
    id: 'machines-bulk-associate-tags',
    getProps: (data, multiSelectValues) => {
      const selectedAtmGobalIds = getSelectedAtmGlobalIds(data, multiSelectValues);
      return { selectedAtmGobalIds };
    },
  },
  1: {
    id: 'machines-bulk-unassociate-tags',
    getProps: (data, multiSelectValues) => {
      const selectedAtmGobalIds = getSelectedAtmGlobalIds(data, multiSelectValues);

      const selectedTags = (
        multiSelectValues
          .map((isSelected, index) => isSelected && data[index]?.tag_ids)
          .filter(Boolean) as string[][]
      ).flat();

      const uniqueSelectedTags = Array.from(new Set(selectedTags));

      return { selectedAtmGobalIds, uniqueSelectedTags };
    },
  },
};

/**
 * Machines Table
 * * For tables we have the TableWrapper component from the Bitstop Theme, for more information please visit the Bitstop Theme documentation
 * @return {*}
 */
const MachinesAtms = () => {
  const { show } = useModal();
  const navigate = useNavigate();
  const [exportMutation] = useGetMachinesExportMutation();

  const [payload, setPayload] = useState<ApiListingRequest>();
  const [multiSelectValues, setMultiSelectValues] = useState<boolean[]>([]);

  const tableRef = useRef<TableWrapperRef>(null);

  const { data: tagsData } = useGetAllTagsByTermQuery({ category: 'machine', term: '' });
  const tagsList = useMemo(() => tagsData?.data ?? [], [tagsData]);
  const { data: severities = [] } = useGetMachinesSeveritiesQuery();

  const filtersConfig = useMemo(() => {
    const config = cloneDeep(MACHINES_FILTERS_CONFIG) as Record<string, any>[];
    //insert statuses options
    config[1].options = [
      ...severities.map(({ label, key }) => {
        return { label: label, value: key };
      }),
    ];
    //insert tags options
    config[3].options = [
      ...tagsList.map(({ id, name, color }) => ({ label: name, value: id, color })),
    ];
    return config as TableToolbarFilterConfig[];
  }, [tagsList, severities]);

  const {
    data: {
      data: apiData = [],
      meta: { pagination: metaPagination = DEFAULT_PAGINATION } = {},
      included,
    } = {},
    isFetching,
  } = useGetMachinesQuery(payload || skipToken);

  const data: WithTags<Machine>[] = useMemo(() => {
    const allTags = (included?.tags ?? []) as Tag[];
    return apiData.map((machine) => ({
      ...machine,
      tags: allTags.filter(({ id }) => machine.tag_ids.includes(id)),
    }));
  }, [apiData]);

  const handleExport = (type: string) => {
    // Use the getExportFile helper to export the Table data using the current payload
    tableRef.current?.getExportFile({
      type,
      mutation: exportMutation,
      fileName: 'Nonce-Atms-Report',
    });
  };

  const handleRow = useCallback(({ id }: Machine) => {
    navigate(`/machine/${id}`);
  }, []);

  const handleMultiSelectActions = (index: number) => {
    const modalAction = multiSelectActions[index] || multiSelectActions[0];
    const modalProps = modalAction.getProps(data, multiSelectValues);
    show(modalAction.id, modalProps);
  };

  return (
    <CardTable>
      <TableWrapper<WithTags<Machine>>
        data={data}
        ref={tableRef}
        hideDateRangePicker
        isLoading={isFetching || !payload}
        sortByDefaultValue="id"
        columns={MACHINES_COLUMNS}
        filterConfigs={filtersConfig}
        totalPages={metaPagination?.total_pages}
        totalItems={metaPagination?.total_items}
        tableProps={{
          placeholder: {
            empty: {
              title: 'No machines found',
              content:
                "We can't find any machine that matches your search, please try a different request",
            },
          },
        }}
        tableToolbarProps={{
          searchPlaceholder: "Search by machine's name, serial, and ID",
        }}
        onChange={setPayload}
        onRowClick={handleRow}
        onActionMenuItemClick={handleExport}
        multiSelectValues={multiSelectValues}
        onMultiSelectValuesChange={setMultiSelectValues}
        tableMultiSelectActionsProps={{
          name: 'Machine(s)',
          config: MACHINES_MULTI_SELECT_CONFIG,
          onClick: handleMultiSelectActions,
        }}
      />
    </CardTable>
  );
};

export default MachinesAtms;
